main {
  /* width: 70%; */
  margin: auto;
  text-align: center;
}

main button {
  margin-top: 20px;
  background-color: #2d8cff;
  color: #ffffff;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}

main button:hover {
  background-color: #2681f2;
}

#zmmtg-root {
  display: none;
  height: 100% !important;
  padding-bottom:constant(safe-area-inset-bottom,105px);
  padding-bottom: env(safe-area-inset-bottom,105px);
}

.footer {
  height: 105px !important;
}

#wc-footer .footer__inner {
  zoom: 150%;
  -webkit-box-pack: center !important;
  display: -webkit-box !important;
}

.footer__btns-container > button,
.footer__btns-container .security-option-menu {
  display: none !important;
}

#content_container {
  width: 100% !important;
  height: 100vh !important;
  align-items: flex-start !important;
}

.root-inner {
  height: 100vh !important;
}

body,
html {
  min-width: unset !important;
}
.ReactModal__Body--open .ReactModalPortal{
  width: 100% !important;
  }
  .ReactModal__Body--open .zm-modal{
    width: 100% !important;
  }
  @media(max-width:480px){
    .ReactModal__Content{
      width: 100% !important;
    }
    .ReactModalPortal .common-window{
      transform: none !important;
      width: 100% !important;
    }
    .ReactModalPortal .settings-dialog .settings-tabs{
      display: block !important;
    }
    .ReactModalPortal .settings-dialog{
      width: 100% !important;
    }
    .ReactModalPortal .mask-area{
      width: 100% !important;
    }
    .ReactModalPortal .settings-dialog__body{
      overflow-y:scroll;
      overflow-x:hidden;
    }
    .reaction-picker--in-more{
      transform: none !important;
      right: 0;
      left:inherit !important;
    }
  }
  .meeting-client .custom-dropdown-menu.dropdown-menu{
    left:-80px !important;
  }
  iframe{
    position: inherit !important;
    z-index: inherit !important;
  }

  .single-suspension-container__wrapper{
    width: 100% !important;
    height: 100% !important;
    
  }

  .single-suspension-container__wrapper .suspension-video-container__canvas{
    width: 100% !important;
    height: 100% !important;
  }

  .single-suspension-container__wrapper .single-suspension-container__canvas{
    width: 100% !important;
    height: 100% !important;
  }

  .single-suspension-container__wrapper .single-suspension-container__video-frame{
    width: 100% !important;
    height: 100% !important;
  }
.single-main-container__video-frame{
  background-color: #fff !important;
  background-image: url('./assets/images/mc-background.jpg') !important;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.single-main-container__video-frame .video-avatar__avatar{
  background:transparent !important
}
.single-main-container__video-frame .video-avatar__avatar-title{
  display: none !important;
}

footer .footer__inner .footer__btns-container{
  display: none !important;
}

.ReactModal__Body--open .ReactModalPortal .zm-modal .zm-modal-footer-default .zm-btn:last-child{
  /* display: none !important; */
}

/* .ReactModal__Body--open .zm-modal.zm-modal-legacy.recording-disclaimer-dialog{
  display: none !important;
} */
.zm-popup__inner-wrapper.zm-action-sheet.join-audio-actionsheet{
  opacity: 0;
}
.notification-message-wrap__layer{
  opacity: 0;
}
@import url('../scss/components/Vendor/font-awesome/font-awesome.min.css');

.header .navbar-bottom .container {
  display: flex;
  align-items: center;
}
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  max-width: 1140px;
}

.container:before,
.container:after {
  content: " ";
  display: table;
}
*:before,
*:after {
  box-sizing: border-box;
}

.header .navbar-bottom .logo {
  width: 130px;
  margin-right: auto;
}
* {
  box-sizing: border-box;
}

a {
  color: #000;
  text-decoration: none;
  transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}

img {
  max-width: 100%;
}
img {
  vertical-align: middle;
}
img {
  border: 0;
}

@media (min-width: 768px) {
  .mobile-nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.header .navbar-bottom .navbar-nav {
  padding: 0;
  margin: 0;
  list-style: none;
}

.header .navbar-bottom .navbar-nav li {
  display: inline-block;
  margin-right: 50px;
}
* {
  box-sizing: border-box;
}
user agent stylesheet li {
  display: list-item;
  text-align: -webkit-match-parent;
}
.header .navbar-bottom .navbar-nav {
  padding: 0;
  margin: 0;
  list-style: none;
}

.header .navbar-bottom .navbar-nav li a {
  font-weight: bold;
  text-transform: capitalize;
  color: #707070;
  font-size: 16px;
}

.header .navbar-bottom .btn-outline {
  height: 48px;
  line-height: 46px;
  min-width: 150px;
  font-size: 16px;
}
.btn-outline.br-theme {
  color: #00b4fa;
  border-color: #00b4fa;
}
.btn-outline {
  font-weight: 600;
  padding: 0 17px;
  letter-spacing: 0.05em;
  font-size: 20px;
  border-radius: 6px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  border: 1px solid;
}

/* footer */
.footer-mcall {
  position: fixed;
  width: 100%;
  bottom: 20px;
}

.footer-mcall .footer-top {
  background: #141ed2;
  color: #fff;
}

.footer-menu {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 34px 0 24px;
  border-bottom: 1px solid #fff;
}

.footer-menu li {
  display: inline-block;
}

.footer-menu li a {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
  color: #fff;
}

.copyright {
  padding: 27px 0;
  color: #fff;
  background: #00b4fa;
}

.btn-location{
  width: 40px;
    height: 40px;
    line-height: 40px;
    background: rgba(0,0,0,0.5);
    color: #fff;
    z-index: 200;
    position: fixed;
    right: 10px;
    top:20%;
    transform: translate(0, -50%);
    padding: 0;
    border-radius:50%;
    i{
      color: #fff;
    }
}

.btn-switch{
  width: 40px;
  height: 40px;
  line-height: 27px;
  position: fixed;
  right: 10px;
  top: 10px;
  background: rgba(0,0,0,0.5);
  color: #fff;
  border-radius:50%;
  i{
    color: #fff;
  }
}

.notification {
	background: #fff;
	transition: .3s ease;
	position: fixed;
	pointer-events: auto;
	overflow: hidden;
	padding: 8px;
	border-radius: 3px 3px 3px 3px;
	box-shadow: 0 0 10px #999;
	color: #000;
	opacity: .9;
	background-position: 15px;
	background-repeat: no-repeat;
  z-index:1000;
}

.notification:hover {
	box-shadow: 0 0 12px #fff;
	opacity: 1;
	cursor: pointer
}

.notification-title {
	font-weight: 700;
	font-size: 16px;
	text-align: left;
	margin-top: 0;
	margin-bottom: 5px;
}

.notification-message {
	margin: 0;
	text-align: left;
    margin-left: -1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
}

.notification-image {
    float: left;
    margin-right: 15px;
}

.notification-image i {
    width: 30px;
    height: 30px;
    line-height: 30px;
}

.toast {
  width: 230px;
	color: #fff;
}

.bottom-right {
	top: 12px;
	right: 12px;
	transition: transform .6s ease-in-out;
	animation: toast-in-right .7s;
}

.success {
  background-color: #5cb85c;
}


@keyframes toast-in-right {
	from {
	  transform: translateX(100%);
	  
	}
	to {
	  transform: translateX(0);
	}
}

.loading{
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index:20;
    background: #fff;
}

.spinning-loader {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid #e5edfc;
  border-left-color: #5a8dee;
  background: transparent;
  animation: rotate-s-loader 1s linear infinite;
  margin: 1rem auto;
}

@keyframes rotate-s-loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}



@-webkit-keyframes fadeExpand {
  from {
    opacity: 0;
    -webkit-transform: scale(0.75); }
  to {
    opacity: 1;
    -webkit-transform: scale(1); } }
@keyframes fadeExpand {
  from {
    opacity: 0;
    -webkit-transform: scale(0.75);
            transform: scale(0.75); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); } }
@-webkit-keyframes fadeShrink {
  from {
    opacity: 1;
    -webkit-transform: scale(1); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.75); } }
@keyframes fadeShrink {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.75);
    transform: scale(0.75); } }



.box-camera{
    opacity: 0;
    position: fixed;
    bottom: 0;
    top: 0;
    right:0;
    width: 100%;
    height: 100vh;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    // background: #222;
  z-index: 101;
  .title{
   
    margin: 20px auto;
    line-height: 24px;
    font-size: 16px;
    color: #fff;
    position: relative;
    span{
      max-width: 150px;
      display: block;
      margin: 0 auto;
    }
   
  }
  .btn-g{
    display: flex;
    align-items: center;
    width: 100%;
  justify-content: center;
 
  padding: 0 10px;
  .btn{
    color: #fff;
  }
  
  }
  .react-html5-camera-photo #display-error{
    display: none !important;
  }
  .demo-image-preview {
    img{
      max-height: 300px;
    }
  }
}

.box-camera.active{
  visibility: visible;
  opacity: 1;
    pointer-events: auto;
     -webkit-animation: fadeExpand .3s ease-in-out 0s, visibility 0s linear .3s;;
  animation: fadeExpand .3s ease-in-out 0s, visibility 0s linear .3s;;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  //overflow:auto;
  -webkit-overflow-scrolling: touch;
}
.box-camera .react-html5-camera-photo>video{
  // position: absolute;
  // top:0;
  // left:0;
}
.box-camera .react-html5-camera-photo{
  // height: 100%;
  // background: #222;
} 
.box-camera .react-html5-camera-photo #outer-circle{
  width: 52px;
  height: 52px;
  background: #fff;
}
.box-camera .react-html5-camera-photo #container-circles{
  //bottom: calc(100vh - 70%) !important;
  display: none !important;
}
.box-camera .react-html5-camera-photo #inner-circle{
  border: 2px solid #222;
  top: 26px;
}

.alert-warning {
  
  position: relative;
  z-index: 1000;
  margin: 0 15px;
  margin-top: 30px;
  font-size: 14px;
  .popup-close{
    width: 30px;
  height: 30px;
  line-height: 30px;
  text-align:center;
  display: block;
  position: absolute;

      right: -15px;
  

  top:-15px;

  cursor: pointer;
  background:#181818;
  border-radius: 50%;
  font-size: 14px;
  z-index: 20;
  }
}

.meeting-info-container.meeting-info-container--left-side{
  display: none;
}

.full-screen-icon{
  display: none !important;
}

$duration: 1.5s;
$timing: ease;

.btn-camera{
  background: linear-gradient(to bottom, #E53038, darken(desaturate(#E53038, 10), 5));
  box-shadow: 0px 7px 20px hsla(0, 0%, 0%, 0.2);
  //animation: beat $duration $timing 0s infinite;
  &:before, &:after {
    content: "";
    position: absolute;
    
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    
    background-color: #E53038;
    opacity: 0.4;
    border-radius: inherit;
  }
  &:before {
    z-index: -2;
    animation: beat-before $duration $timing 100ms infinite;
  }
  
  &:after {
    z-index: -1;
    animation: beat-after $duration $timing 200ms infinite;
  }
}

@keyframes beat {
  0% {
    transform: scale(1);
  }
  
  50% {
    transform: scale(1.1);
  }
}

@keyframes beat-before {
  0% {
    transform: scale(1);
  }
  
  50% {
    transform: scale(1.15);
  }
}

@keyframes beat-after {
  0% {
    transform: scale(1);
  }
  
  50% {
    transform: scale(1.3);
  }
}

// modal

.modal-popup_style2 {
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 99999;
  
  outline: 0;
  text-align:center;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  visibility: hidden;
  
  &:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      margin-right: -4px;
  }

  &.open {
      visibility: visible;
      bottom: 0;
  top: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  }
}



.popup-container {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  background: #fff;
  
}

.pu-permision{
  width: calc(100% - 20px);
  .title{
    font-size: 16px;
    font-weight: bold;
    border-bottom:1px solid #eee;
    padding: 10px;
    margin: 0;
  }
  .content{
    font-size: 14px;
    padding: 10px;
  }
  .btn-g{
    display: flex;
    justify-content: center;
    .btn{
      margin-right:10px;
      min-width: 90px;
      padding: 0 10px;
      height: 36px;
      line-height: 36px;
      font-size: 14px;
      font-weight: bold;
      &:not(.btn-permision){
        border: 1px solid #ddd;
      }
    }
    .btn-permision{
      background-color: #E53038;
      color: #fff;
    }
  }
}


.pu-feedback{
  position: relative;
  width: calc(100% - 20px);
  padding: 15px;
  .popup-close {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align:center;
    display: block;
    position: absolute;
  
    right: 5px;
    top: 3px;
  
    cursor: pointer;
    border-radius: 50%;
    font-size: 14px;
    z-index: 20;
    [class*="fa"] {
        color: #000;
    }
  
  
  }
  .form-select{
    height: 36px;
    font-size: 14px;
    width: 100%;
  }
  .form-input{
    &:not(:last-child){
      margin-bottom: 10px;
    }
  }
  .label-name{
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 5px;
    display: block;
  }
  textarea.form-control{
    height: 100px;
    
  }
  .form-control{
    font-size: 14px;
  }
  .btn{
    background: #00b4fa;
    border:none;
    color: #fff;
    font-size: 14px;
    padding: 8px;
    
    min-width: 120px;
  }
}